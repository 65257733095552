import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { AuthService } from 'src/app/auth.service';

@Injectable({
  providedIn: 'root'
})

export class ServerService {
  
  /* eslint-disable @typescript-eslint/no-explicit-any */
  public logInGithub = new EventEmitter<any>();
  public logoutGithub = new EventEmitter<any>();
  public updateRepoData = new EventEmitter<any>();
  public updateIsSelectedFile = new EventEmitter<any>();

  public requestUrl = environment.requestUrl;
  public dashboardUrl = environment.dashboardUrl;
  public headers: any;
  public isMobile: boolean = false;
  public isAuth: boolean = false;
  public repoData: any;
  public isRepoDataSet: boolean = false;
  public isSelectedFile: boolean = true;

  constructor(private http: HttpClient, private deviceService: DeviceDetectorService, private router: Router, private authService: AuthService) {
    this.isMobile = this.deviceService.isMobile();

    this.logInGithub.subscribe(() => {
      this.isAuth = true;
      window.location.href = environment.homeUrl;
    });

    this.logoutGithub.subscribe(() => {
      this.isAuth = false;
      window.location.href = environment.homeUrl;
    });

    this.updateRepoData.subscribe(() => {
      this.isRepoDataSet = true;
    });
  }

  private async request(method: string, url: string, type: any, data?: any, headers?: any) {
    const timestamp = Math.floor(Date.now() / 1000); // Unix timestamp in seconds
    const sharedSecret = environment.secret;
    data.timestamp = timestamp;

    // Sign the request with the shared secret
    const signature = CryptoJS.HmacSHA256(JSON.stringify(data), sharedSecret).toString(CryptoJS.enc.Hex);
        
    // Set signature in headers
    let tmpheaders = new HttpHeaders({
      'x-access-token': environment.originCode, 
      'x-signature': signature,
      'x-timestamp': timestamp
    })

    const result = this.http.request(method, url, {
      body: data,
      responseType: type,
      observe: 'response', // Changed from 'body' to 'response' to access status code
      headers: tmpheaders
    });

    return new Promise((resolve, reject) => {
      result.subscribe({
        next: (response) => {
          resolve(response.body); // Resolve with the body of the response
        },
        error: (error) => {
          if (error.status === 404) {
            // Redirect to the 404 page
            console.log("error: "+error.toString());
            this.router.navigate(['/404']);
          }
          reject(error);
        },
      });
    }
    )
  }

private async requestUpload(method: string, url: string, type: any, data?: any, headers?: any): Promise<any> {
  const timestamp = Math.floor(Date.now() / 1000); // Unix timestamp in seconds
  const sharedSecret = environment.secret;
  data.timestamp = timestamp;

  // Sign the request with the shared secret
  const signature = CryptoJS.HmacSHA256(JSON.stringify(data), sharedSecret).toString(CryptoJS.enc.Hex);
      
  // Set signature in headers
  let tmpheaders = new HttpHeaders({
    'x-access-token': environment.originCode, 
    'x-signature': signature,
    'x-timestamp': timestamp
  });

  try {
    // Make the HTTP post request and return the observable
    return this.http.post<any>(url, data, {
      responseType: type,
      headers: tmpheaders
    }).toPromise(); // Convert the observable to a promise
  } catch (error: any) {
    if (error.status === 404) {
      // Redirect to the 404 page
      this.router.navigate(['/404']);
    } 
    throw error;
  }
}

private async requestWithDashboardJWT(method: string, url: string, type: any, data?: any, headers?: any) {
  const timestamp = Math.floor(Date.now() / 1000); // Unix timestamp in seconds
  data !== null ? data.timestamp = timestamp : data = {timestamp};
  const sharedSecret = environment.secret;
  const jwt = this.authService.getToken();
  if (jwt === null || !jwt){
    console.log("no JWT");
    this.authService.logout();
    return;
  }


  // Sign the request with the shared secret
  const signature = CryptoJS.HmacSHA256(JSON.stringify(data), sharedSecret).toString(CryptoJS.enc.Hex);
      
  // Set signature in headers
  let tmpheaders = new HttpHeaders({
    'x-access-token':  environment.originCode, 
    'x-signature': signature,
    'x-timestamp': timestamp,
    'authorization': jwt,
    'x-dashboard-origin': environment.homeUrl
  })

  const result = this.http.request(method, url, {
    body: data,
    responseType: type,
    observe: 'response', // Changed from 'body' to 'response' to access status code
    headers: tmpheaders,
  });

  return new Promise((resolve, reject) => {
    result.subscribe({
      next: (response) => {
        resolve(response.body); // Resolve with the body of the response
      },
      error: (error) => {
        if (error.status === 404) {
          // Redirect to the 404 page
          this.router.navigate(['/404']);
        }
        if (error.status === 401) {
          // Redirect to the dashboard login
          this.authService.logout();
          this.router.navigate(['/dashboard']);
        }
        reject(error);
      },
    });
  }
  )
}



  /**
  * Calling the HTTP request module to generate and send the token to the Mail
  * @returns 
  */
  async SendTokenMail(obj: any): Promise<any> {
    try {
      const nextValue = await this.request('POST', `${this.requestUrl}/sendTokenMail/`, 'json', obj, this.headers);
      return nextValue;
    } catch (errorValue) {
      throw errorValue;
    }
  }

  async SendReportTokenMail(obj: any): Promise<any> {
    try {
      const nextValue = await this.request('POST', `${this.requestUrl}/sendReportTokenMail/`, 'json', obj, this.headers);
      return nextValue;
    } catch (errorValue) {
      throw errorValue;
    }
  }


  /**
    * Calling the HTTP request module to call verify the token in api index
    * @param obj
    * @returns 
    * 
    */
  VerifyToken(obj: any) {
    const res = this.request('POST', `${this.requestUrl}/verifyToken/`, 'json', obj, this.headers);
    return res;
  }

  /**
  * Calling the HTTP request module to call verify the token in api index
  * @param obj
  * @returns 
  * 
  */
  VerifyReportToken(obj: any) {
    const res = this.request('POST', `${this.requestUrl}/verifyReportToken/`, 'json', obj, this.headers);
    return res;
  }

  /**
  * Calling the HTTP request module to call getRepoSize in api_audit 
  * @param obj
  * @returns 
  * 
  */
  getRepoSize(obj: any) {
    const res = this.request('POST', `${this.requestUrl}/getRepoSize/`, 'json', obj, this.headers);
    return res;
  }

  async uploadFile(formData: FormData): Promise<any> {
    try {
      const response = await this.requestUpload('POST', `${this.requestUrl}/upload`, 'json', formData);
      return response;
    } catch (error) {
      throw error;
    }
  }
  

  /**
  * Calling the HTTP request module to call callback in api_audit from api.github
  * @param obj
  * @returns 
  * 
  */
  getCallback(obj: any) {
    const res = this.request('POST', `${this.requestUrl}/callback/`, 'json', obj, this.headers);
    return res;
  }

  /**
  * Calling the HTTP request module to call callback in api_audit from api.github
  * @param obj
  * @returns 
  * 
  */
  deleteToken(obj: any) {
    const res = this.request('POST', `${this.requestUrl}/deleteToken/`, 'json', obj, this.headers);
    return res;
  }

  /**
  * Calling the HTTP request module to call createAssement reCaptcha and retrieve score
  * @param obj
  * @returns 
  * 
  */
  getScore(obj: any) {
    const res = this.request('POST', `${this.requestUrl}/getScore/`, 'json', obj, this.headers);
    return res;
  }

  
  /**
  * Calling the HTTP request module to call the results route, retrieve result data from hash
  * @param obj
  * @returns 
  * 
  */
  handleHash(obj: any) {
    const res = this.request('POST', `${this.requestUrl}/results/`, 'json', obj, this.headers);
    return res;
  }

  /**
 * Calling the HTTP request module to call the results route, retrieve result data from hash
 * @param obj
 * @returns 
 * 
 */
  handleHashReport(obj: any) {
    const res = this.request('POST', `${this.requestUrl}/report/`, 'json', obj, this.headers);
    return res;
  }
  /**
  * Calling the HTTP request module to call the results route, retrieve result data from hash
  * @param obj
  * @returns 
  * 
  */
  async getDisplayData(obj: any) {
    const displayData = this.request('POST', `${this.requestUrl}/resultData/`, 'json', obj, this.headers);

    if (displayData) {
      return displayData;
    } else {
      return 'Could not fetch the data from the API';
    }
  }


  // ----------------------------------------------------------------  DASHBOARD ---------------------------------------------------------------- //

   /**
   * Calling the HTTP request module to register a new user
   * @param user - The user object containing sign-up details
   * @returns 
   */
   async registerUser(user: { email: string; password: string }): Promise<any> {
    return await this.request('POST', `${this.dashboardUrl}/register`, 'json', user, this.headers)
      .then((nextValue) => {
        return nextValue; // Return the successful result
      })
      .catch((errorValue) => {
        throw errorValue;
      });
  }

  /**
   * Calling the HTTP request module to register a new user
   * @param user - The user object containing sign-up details
   * @returns 
   */
  async loginUser(user: { email: string; password: string }): Promise<any> {
    return this.request('POST', `${this.dashboardUrl}/login`, 'json', user, this.headers)
      .then((nextValue) => {
        return nextValue; // Return the successful result
      })
      .catch((errorValue) => {
        throw errorValue;
      });
  }

    /**
   * Calling the HTTP request module to request a password reset
   * @param email - The email associeted with account
   * @returns 
   */
  async requestPasswordReset(email: string): Promise<any> {
    const data = { email: email };
    return this.request('POST', `${this.dashboardUrl}/sendMailResetPassword`, 'json', data, this.headers)
      .then((nextValue) => {
        return nextValue; // Return the successful result
      })
  }

  /**
   * Calling the HTTP request module to confirm the email on signup
   * @param email - The email associeted with account
   * @returns 
   */
  async requestSignupCode(email: string): Promise<any> {
    const data = { email: email };
    return this.request('POST', `${this.dashboardUrl}/sendMailSignupCode`, 'json', data, this.headers)
      .then((nextValue) => {
        return nextValue; // Return the successful result
      })
  }

  /**
    * [ For Dashboard ] Calling the HTTP request module to call verify the token in api index
    * @param obj
    * @returns 
    * 
    */
  async VerifyTokenDashboard(obj: any): Promise<any> {
    const data = { data : obj}
    const res = this.request('POST', `${this.dashboardUrl}/verifyToken/`, 'json', data, this.headers);
    return res;
  }

  /**
    * [ For Dashboard ] Calling the HTTP request module to delete the JWT token
    * @returns 
    * 
    */
  async logoutDashboard(): Promise<any> {
    const res = this.requestWithDashboardJWT('POST', `${this.dashboardUrl}/logoutDashboard`, 'json', null, this.headers);
    return res;
  }

  /**
   * Calling the HTTP request module to change user password
   * @param user - The user object containing sign-up details
   * @returns 
   */
  async updatePassword(user: { email: string; password: string }): Promise<any> {
    return this.request('PUT', `${this.dashboardUrl}/updatePassword`, 'json', user, this.headers)
      .then((nextValue) => {
        return nextValue; // Return the successful result
      })
      .catch((errorValue) => {
        throw errorValue;
      });
  }

  /**
 * Calling the HTTP request module to fetch audit report history with pagination
 * @param page - The page number to fetch (default: 1)
 * @param itemsPerPage - The number of items per page (default: 10)
 * @returns Promise with audit report data and pagination info
 */
async getAuditReportHistory(page: number = 1, itemsPerPage: number = 10): Promise<{
  data: any[];
  totalPages: number;
  currentPage: number;
}> {
  // Construct the API URL with pagination parameters
  const url = `${this.dashboardUrl}/auditReportHistory?page=${page}&itemsPerPage=${itemsPerPage}`;
  
  try {
    const response:any = await this.requestWithDashboardJWT('GET', url, 'json', null, this.headers);
    
    // Assuming the API returns the data in the expected format
    // If not, you may need to transform the response here
    return {
      data: response.data || [],
      totalPages: response.totalPages || 1,
      currentPage: response.currentPage || page
    };
  } catch (error) {
    console.error('Error fetching audit report history:', error);
    throw error; // Re-throw the error for the component to handle
  }
}
  
}