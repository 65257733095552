<h2>Reset Password</h2>

<!-- Form to request reset code -->
<form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" *ngIf="!codeSent">
  <div class="form-group">
    <label for="email">Enter your email address</label>
    <input type="email" id="email" formControlName="email" placeholder="Email" />
    <div *ngIf="forgotPasswordForm.get('email')!.invalid && forgotPasswordForm.get('email')!.touched" class="error">
      Please enter a valid email address.
    </div>
  </div>
  <div class="actions">
    <button type="button" (click)="onCancel()">Cancel</button>
    <button type="submit" [disabled]="forgotPasswordForm.invalid">Submit</button>
  </div>
</form>

<!-- Form to enter the code after it's sent -->
<form [formGroup]="forgotPasswordCodeForm" (ngSubmit)="onSubmitCode()" *ngIf="codeSent && !codeValid">
  <div class="form-group">
    <label for="code">Enter the code sent to your email</label>
    <input type="text" id="code" formControlName="code" placeholder="Code" />
    <div *ngIf="forgotPasswordCodeForm.get('code')?.invalid && forgotPasswordCodeForm.get('code')?.touched" class="error">
      Please enter the code.
    </div>
  </div>
  <div class="actions">
    <button type="button" (click)="onBack()">Back</button>
    <button type="submit" [disabled]="forgotPasswordCodeForm.get('code')?.invalid">Submit Code</button>
  </div>
</form>

<div class="divChangePassword" *ngIf="codeValid">
  <app-change-password  [userEmail]="this.userEmail"></app-change-password>
</div>
