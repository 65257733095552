import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServerService } from 'src/app/server.service';
import { environment } from 'src/app/environments/environment';
import * as CryptoJS from 'crypto-js';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './dashboard-forgot-password-dialog.component.html',
  styleUrls: ['./dashboard-forgot-password-dialog.component.css', '../../../app.component.css']
})
export class DashboardForgotPasswordDialogComponent {
  forgotPasswordForm: FormGroup;
  forgotPasswordCodeForm: FormGroup;
  codeSent: boolean = false;
  codeValid: boolean = false;
  hashedToken: string = '';
  userEmail : string = '';

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DashboardForgotPasswordDialogComponent>,
    private serverService: ServerService,
    private snackBar: MatSnackBar
  ) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.forgotPasswordCodeForm = this.fb.group({
      code: ['', [Validators.required, Validators.min(8)]]
    });
  }

  async onSubmit(): Promise<void> {
    if (this.forgotPasswordForm.valid) {
      const email = this.forgotPasswordForm.value.email;
      this.userEmail = email;
      const encryptedEmail = CryptoJS.AES.encrypt(email, environment.secretKey).toString();
      await this.serverService.requestPasswordReset(encryptedEmail)
      .then((response: any) => {
        // console.log('Email sent successfully', response);
        this.codeSent = true;
      })
      .catch((error: any) => {
        // console.error('Email sending failed', error);
        this.snackBar.open('This email does not exists, please try again or Sign Up', 'Close', {
          duration: 7000,
          panelClass: ["snackBarError"]
        });
    });

    }
  }

  async onSubmitCode(): Promise<void> {
    if (this.forgotPasswordCodeForm.valid) {
      const enteredCode = this.forgotPasswordCodeForm.value.code;
      const email = this.userEmail;
      const obj = { token: enteredCode, email: email, isNewAccount:false};
      // console.log(obj)
      const jsonStr = JSON.stringify(obj) // Convert the object to a JSON string
      const encryptedObject = CryptoJS.AES.encrypt(jsonStr, environment.secretKey).toString()
      await this.serverService.VerifyTokenDashboard(encryptedObject)
      .then((response: any) => {
        // console.log('The code is Valid', response);
        this.codeValid = true;
      })
      .catch((error: any) => {
        // console.error('token verif error', error);
        this.snackBar.open('The code is not valid, try again.', 'Close', {
          duration: 7000,
          panelClass: ["snackBarError"]
        });
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

 


  onBack(): void {
    this.codeSent = false;
    this.hashedToken = ''; // Reset the token
  }
}
