<div class="container mt-4">
  <table class="table table-bordered text-center">
    <thead class="thead-dark">
      <tr>
        <th scope="col">GitHub</th>
        <th scope="col">Status</th>
        <th scope="col">Sec Grade (/5)</th>
        <th scope="col">Report</th>
        <th scope="col">Creation</th>
        <th scope="col">Expiration</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let report of auditReports">
        <td>
          <ng-container *ngIf="isGithubRepo(report.github); else plainText">
            <a [href]="report.github" target="_blank" class="text-success">{{ getRepoName(report.github) }}</a>
          </ng-container>
          <ng-template #plainText>
            {{ report.github }}
          </ng-template>
        </td>
        <td>{{ report.status }}</td>
        <td>{{ report.grade }}</td>
        <td><a [href]="report.reportURL" target="_blank" class="text-success">Report</a></td>
        <td>{{ report.resultCreationTime | date:'EEE, dd MMM yyyy HH:mm:ss' }} GMT</td>
        <td>{{ report.resultExpirationTime | date:'EEE, dd MMM yyyy HH:mm:ss' }} GMT</td>
      </tr>
    </tbody>
  </table>

  <!-- Loading indicator -->
  <div *ngIf="loading" class="text-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <!-- Pagination -->
  <nav aria-label="Audit reports pagination" *ngIf="!loading && totalPages > 1" class="pagination-container">
    <ul class="pagination">
      <li class="page-item" [class.hidden]="currentPage === 1">
        <a class="page-link prev" href="#" (click)="previousPage(); $event.preventDefault()" aria-label="Previous page">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li class="page-item" *ngFor="let page of [].constructor(totalPages); let i = index"
          [class.active]="currentPage === i + 1">
        <a class="page-link" href="#" (click)="goToPage(i + 1); $event.preventDefault()">{{ i + 1 }}</a>
      </li>
      <li class="page-item" [class.hidden]="currentPage === totalPages">
        <a class="page-link next" href="#" (click)="nextPage(); $event.preventDefault()" aria-label="Next page">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</div>